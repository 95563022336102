// import { DomHandler } from 'primevue/utils';

const numberTransform = {
	mounted(el: HTMLElement, binding: any) {
		const { modifiers, value } = binding
		const target = el.textContent

		// value가 숫자면 thousnad comma를 붙여준다.
		// console.log('value', modifiers, value, el.textContent)
		if (modifiers.comma && target) {
			if (!isNaN(Number(target))) {
				// 값이 숫자인 경우만 처리
				el.textContent = Number(target).toLocaleString('en')
			}
		}

		// if (DomHandler.isClient()) {
		// }
	}
}

export default numberTransform
