import { defineStore } from 'pinia'

export const useUIStore = defineStore('ui', () => {
	// const isLoading = ref(false)
	const loadingMap: Ref<{ [key: string]: boolean }> = ref({})
	const isLoading = computed(() => {
		return Object.values(loadingMap.value).some((value) => value === true)
	})

	function setLoading(tf: boolean, key: string) {
		if (key) loadingMap.value[key] = tf
	}

	function setLoadingForce(tf: boolean) {
		// isLoading.value = tf
		if (tf) {
			setLoading(true, 'page')
		} else {
			loadingMap.value = {}
		}
	}

	return { isLoading, setLoading, setLoadingForce }
})
