// import { DomHandler } from 'primevue/utils';

const dateTransform = {
	mounted(el: HTMLElement, binding: any) {
		const { modifiers, value } = binding
		const target = el.textContent

		if (modifiers.date && target) {
			if (target) {
				el.textContent = target.split('T')[0].replace(/-/g, '.')
			}
		}
	}
}

export default dateTransform
