// 이용 상태
// A(Activate): 활성화(정상) C3400001
// D(Deactivate) : 비활성화 C3400002
// L(long term) : 활성화(장기 미접속) C3400003
export enum useStateEnum {
	A = 'C3400001',
	D = 'C3400002',
	L = 'C3400003'
}

export enum sortEnum {
	A = 'ASC',
	D = 'DESC'
}

export enum paymentCycleEnum {
	Y = 'C3200001', //연간
	M = 'C3200002' //월간
}

export enum termCycleEnum {
	M = 'C0100003', // 월
	W = 'C0100002', // 주
	D = 'C0100001' // 일
}

export enum priceEnum {
	KRW = 'C1300001', //원
	USD = 'C1300002' //달러
}

export enum abnomalTypeCode {
	A = 'C1700005', // 기획서에서 전체 (All), API에서 AI
	N = 'C1700001', // 네트워크 (Network)
	U = 'C1700003', // 사용자 (User)
	D = 'C1700002', // 기획서에서 기기, API에서 단말 (Device)
	E = 'C1700004' // 기획서에서 기타 (Etc), API에서 사용자 신고
}

export enum abnormalDetectCode {
	F = 'C2400001', // 해외접속 (Foreign)
	R = 'C2400002', // 퇴사자 계정 접속 (Resigned)
	U = 'C2400003', // 비인가 단말 접속 (Unauthorized)
	O = 'C2400004', // 시외 IP 사용 접속 (Outbound)
	A = 'C2400005', // 이상 행동 감지 (Abnormal)
	M = 'C2400006', // 불가능 접속 이동 감지 (Movement)
	T = 'C2400007', // 사용자 신고 (User Report)
	E = 'C2400008' // 기타 (Etc)
}

export enum actionStatusCode {
	N = 'C1800001', // 미조치 (No action)
	W = 'C1800002', // 경고: 이메일 발송 (Warning)
	S = 'C1800003', // 아이디 정지 (Suspend)
	L = 'C1800004', // 강제 로그아웃 (Logout)
	P = 'C1800005', // 비밀번호 초기화 (Password reset)
	T = 'C1800006', // 로그인 2차 인증 설정 (Two-factor authentication)
	X = 'C1800007', // 조치 대상 아님 (Exclude)
	U = 'C1800008' // 차단 해제 (Unblock)
}

export enum apprResultEnum {
	WAITING = 'C3300001',
	APPROVED = 'C3300002',
	REJECTED = 'C3300003'
}
// C3300001	결재 대기
// C3300002	승인
// C3300003	반려

export enum apprTypeEnum {
	APPLY = 'C3600001',
	RELEASE = 'C3600002',
	EXTEND = 'C3600003',
	SUSPEND = 'C3600004',
	RESUME = 'C3600005'
}
// C3600001	권한 신청
// C3600002	권한 해제
// C3600003	기간 연장
// C3600004	일시 정지
// C3600005	일시 정지 해제

export enum actionEnum {
	WARNING = '경고',
	BLOCK = '접속 차단',
	EXPIRED = '세션 종료',
	PASSWORD = '비밀번호 초기화',
	MFA = 'MFA 인증 필수 추가',
	ADMIN = '관리자 확인',
	XEXCLUSION = '제외'
}
// W(arning) : 경고
// B(lock) : 접속 차단
// E(xpired session) : 세션 종료
// P(assword reset) : 비밀번호 초기화
// M(FA) : MFA 인증 필수 추가
// A(dmin) : 관리자 확인
// X(exclusion) : 제외

export enum deviceEnum {
	WINDOW = 'C2000001',
	MAC = 'C2000002',
	AOS = 'C2000003',
	IOS = 'C2000004'
}

// 사용자 > 등록 서비스 신청
export enum seviceRequestEnum {
	REVOKE = 'revoke_auth', // 권한 해제
	EXTENSION = 'period_extension', // 기간 연장
	PAUSE = 'pause', // 일시 정지 (사용 X)
	RESUME = 'resume' // 일시 정지 해제
}

/*
	접속이력타입
	C5500001 : 로그인
	C5500002 : 보안
*/
export enum securityTypeCodeEnum {
	LOGIN = 'C5500001',
	SECURITY = 'C5500002'
}

/*
	앱 관리
*/
export enum appManageTabEnum {
	APP, // 앱 목록
	REQUEST, // 앱 신청 내역
	APPROVAL // 앱 결재 내역
}

/*
	서비스 사용 상태
	C4100001        정상
	C4100002        일시 정지됨
	C4100003        기간 만료 임박
*/
export enum useStatusEnum {
	NORMAL = 'C4100001',
	PAUSE = 'C4100002',
	EXPIRE = 'C4100003'
}

export function reverseMap(enumObj: any): any {
	const result: any = {}
	for (const key in enumObj) {
		result[enumObj[key]] = key
	}
	return result
}
