interface Codes {
	[key: string]: string
}
export const periodCode: Codes = {
	Y: '년',
	M: '월'
}

export const priceCode: Codes = {
	KRW: '원',
	USD: '달러'
}

export const apprTypeCode: Codes = {
	APPLY: '권한 신청',
	RELEASE: '권한 해제',
	EXTEND: '기간 연장',
	SUSPEND: '일시 정지',
	RESUME: '일시 정지 해제',
	C3600001: '권한 신청',
	C3600002: '권한 해제',
	C3600003: '기간 연장',
	C3600004: '일시 정지',
	C3600005: '일시 정지 해제'
}
// C3600001	권한 신청
// C3600002	권한 해제
// C3600003	기간 연장
// C3600004	일시 정지
// C3600005	일시 정지 해제

export const apprResultCode: Codes = {
	WAITING: '결재 대기',
	APPROVED: '승인',
	REJECTED: '반려',
	C3300001: '결재 대기',
	C3300002: '승인',
	C3300003: '반려'
}

// 알림 종류(카테고리)
export const announceTypeCode: Codes = {
	C0400001: '사용자',
	C0400002: '서비스',
	C0400003: '보안',
	C0400004: '기타',
	C0400005: '신청',
	C0400006: '결재',
	C0400007: '답변',
	C0400008: '공지사항'
}
