<template>
	<BlockUI full-screen :blocked="loader.isLoading" :pt="blockPT" class="full-block" />
	<div class="ui-loader" v-if="loader.isLoading">
		<ProgressSpinner class="spinner bg" strokeWidth="4" />
		<ProgressSpinner class="spinner" strokeWidth="4" animationDuration="0" aria-label="로딩중..." />
	</div>
</template>

<script lang="ts" setup>
const loader = reactive(useLoader())

const blockPT = {
	mask: {
		class: 'full-block-mask'
	}
}
</script>

<style lang="scss">
// 전부 글로벌
.ui-loader {
	position: fixed;
	z-index: 999 !important;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80px;
		height: 80px;
		background-color: transparent;
		.p-progress-spinner-circle {
			stroke: var(--color-primary-600);
			animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
		}
		&.bg {
			.p-progress-spinner-circle {
				animation: none;
				stroke: var(--color-primary-100);
				stroke-dasharray: 0;
			}
		}
	}
}

.full-block-mask {
	z-index: 998 !important;
	border-radius: 0;
	// background-color: transparent;
}
</style>
