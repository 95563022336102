<template>
	<Button v-bind="{ ...$attrs, ...customProps }" :pt="buttonPT" class="p-button" :class="className" :disabled="disabled"
		><slot></slot
	></Button>
</template>

<script setup lang="ts">
defineOptions({
	inheritAttrs: false
})
const props = withDefaults(
	defineProps<{
		severity?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'error' | undefined
		primary?: boolean | undefined
		secondary?: boolean | undefined
		success?: boolean | undefined
		info?: boolean | undefined
		warning?: boolean | undefined
		error?: boolean | undefined
		outlined?: boolean | undefined
		text?: boolean | undefined
		block?: boolean | undefined
		small?: boolean | undefined
		large?: boolean | undefined
		xLarge?: boolean | undefined
		textForce?: boolean | undefined
		textLink?: boolean | undefined
		icon?: boolean | undefined
		disabled?: boolean | undefined
		className?: string | undefined
	}>(),
	{
		outlined: (props) => !!props.outlined || false,
		text: (props) => !!props.text || false
	}
)

const customProps = computed(() => {
	return {
		severity:
			props.severity || props.primary
				? 'primary'
				: props.secondary
					? 'secondary'
					: props.success
						? 'success'
						: props.info
							? 'info'
							: props.warning
								? 'warning'
								: props.error
									? 'error'
									: '',
		outlined: !props.textForce && (props.outlined || (!props.text && props.secondary)),
		text: props.text || props.textForce
	}
})

const buttonPT = {
	root: {
		class: {
			'p-block': props.block,
			'p-button-sm': props.small === true,
			'p-button-md': !props.small && !props.large && !props.xLarge,
			'p-button-lg': props.large === true,
			'p-button-xl': props.xLarge === true,
			'p-button-textforce': props.textForce === true,
			'p-buttom-primary': props.primary === true,
			'p-buttom-secondary': props.secondary === true,
			'p-button-link': props.textLink === true,
			'p-button-icon': props.icon === true
		}
	}
}
</script>

<style lang="scss" scoped>
.p-disabled {
	pointer-events: inherit;
}

.p-block {
	display: block;
	width: 100%;
}

:deep(.pp-icon) {
	margin-right: 4px;
}

.p-button-lg {
	padding: 7px 16px;
}
// .p-button-textforce {
//   padding: 0;
//   background-color: transparent;
//   border-color: transparent;
// }
</style>
