import { defu } from 'defu'
import { useDialog as primevueDialog } from 'primevue/usedialog'
import { useConfirm as primevueConfirm } from 'primevue/useconfirm'
import { useToast as primevueToast } from 'primevue/usetoast'
import type { ConfirmationOptions } from 'primevue/confirmationoptions'

interface CustomConfirmationOptions extends ConfirmationOptions {
	defaultFocus?: 'accept' | 'reject'
	closeOnEscape?: boolean
	message?: string | undefined
	acceptLabel?: string
	rejectLabel?: string
}

interface ModalOptions {
	props: {
		modal?: boolean
		blockScroll?: boolean
		dismissableMask?: boolean
		size?: 'small' | 'default' | 'large' | 'full'
		[key: string]: any
	}
	templates?: {
		header?: any
		footer?: any
	}
	data?: object
	emits?: object
	[key: string]: any
}

export const useDialogs = () => {
	const dialog = primevueDialog()
	const confirm = primevueConfirm()
	const toast = primevueToast()

	const Dialog = (component: any, options: ModalOptions = { props: {} }) => {
		const defaultOptions: ModalOptions = {
			props: {
				modal: true,
				blockScroll: true,
				dismissableMask: true,
				closeOnEscape: false,
				size: 'default',
				pt: {
					root: 'p-modal dialog-wrap'
				}
			}
		}

		const mergedOptions: ModalOptions = {
			...defaultOptions,
			...options,
			props: { ...defaultOptions.props, ...options.props }
		}

		dialog.open(component, {
			// onClose: (e: any) => {
			// 	console.log(e)
			// },
			...mergedOptions
		})
	}

	const Confirm = async (message: string, require?: CustomConfirmationOptions) => {
		return new Promise((resolve, reject) => {
			confirm.require(<CustomConfirmationOptions>{
				blockScroll: true,
				message,
				defaultFocus: 'accept',
				closeOnEscape: false,
				...require,
				pt: { root: 'confirm-wrap' },
				accept: () => resolve(true),
				reject: () => resolve(false),
				onShow: () => {
					nextTick(() => {
						setTimeout(() => {
							const acceptButton = document.querySelector('.btn-confirm-ok')
							// console.log('acceptButton:', acceptButton)
							if (acceptButton instanceof HTMLElement) {
								acceptButton.focus()
							}
						}, 100)
					})
				}
			})
		})
	}

	const Alert = async (message: string) => {
		return new Promise((resolve, reject) => {
			confirm.require(<CustomConfirmationOptions>{
				blockScroll: true,
				message,
				group: 'alert',
				defaultFocus: 'accept',
				closeOnEscape: false,
				accept: () => resolve(true),
				onShow: () => {
					nextTick(() => {
						setTimeout(() => {
							const acceptButton = document.querySelector('.btn-alert-ok')
							if (acceptButton instanceof HTMLElement) {
								acceptButton.focus()
							}
						}, 100)
					})
				}
			})
		})
	}

	const AlertWithAction = async (message: string, callback: Function) => {
		return new Promise((resolve, reject) => {
			confirm.require(<CustomConfirmationOptions>{
				blockScroll: true,
				message,
				group: 'alert',
				defaultFocus: 'accept',
				closeOnEscape: false,
				accept: () => {
					callback()
				},
				onShow: () => {
					nextTick(() => {
						setTimeout(() => {
							const acceptButton = document.querySelector('.btn-alert-ok')
							if (acceptButton instanceof HTMLElement) {
								acceptButton.focus()
							}
						}, 100)
					})
				}
			})
		})
	}

	const Toast = (options: any) => {
		const defaultOptions = <any>{
			group: 'toast',
			severity: 'secondary',
			summary: 'Info Message',
			detail: null,
			life: 3000
		}

		return toast.add(defu(options, defaultOptions))
	}

	return { Dialog, Confirm, Alert, AlertWithAction, Toast }
}
