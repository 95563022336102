// plugins/disable-console.js
export default defineNuxtPlugin(() => {
	/*
	if (process.env.NODE_ENV === 'production') {
		console.log = () => {}
		console.info = () => {}
		console.warn = () => {}
		console.error = () => {}
	}
	*/
})