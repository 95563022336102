import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserStore = defineStore('user', () => {
	const authStore = useAuthStore()

	const name = ref('')
	const phone = ref('')
	const email = ref('')
	const companyName = ref('')
	const subscribeYn = ref('')

	// 초기 설정
	const initUser = ref({
		name: '',
		phone: '',
		email: '',
		companyName: '',
		subscribeYn: ''
	})

	const setEmail = (v: string) => {
		email.value = v
	}

	const setPhone = (v: string) => {
		phone.value = v
	}

	const setName = (v: string) => {
		name.value = v
	}

	const setCompanyName = (v: string) => {
		companyName.value = v
	}

	const setSubscribeYn = (v?: string) => {
		subscribeYn.value = v || ''
	}

	const setUserData = (data: { name: string; phoneNumber: string; email: string; companyName: string; subscribeYn?: string; }) => {
		setName(data.name)
		setPhone(data.phoneNumber)
		setEmail(data.email)
		setCompanyName(data.companyName)
		setSubscribeYn(data.subscribeYn)
		/*
		if (data.email) {
			console.log('🟢 세션 체크 결과 유저 정보 있음 ::::::::: ', data)
		} else {
			authStore.logout()
			console.log('🔴 세션 체크 결과 유저 정보 없음 ::::::::: ')
		}
		*/
	}

	return {
		name,
		phone,
		email,
		companyName,
		subscribeYn,
		initUser,
		setName,
		setPhone,
		setEmail,
		setCompanyName,
		setSubscribeYn,
		setUserData
	}
})
