export enum RefreshBlockPages {
	Login = 'login',
	LoginHelp = 'loginHelp',
	Join = 'join'
}

enum RoutesConstants {
	MAIN = 'main',
	HOME = 'index',
	AUTH_SIGNUP_INDEX = 'auth-signup',
	AUTH_SIGNUP_BASIC = 'auth-signup-basic',
	AUTH_SIGNUP_ID = 'auth-signup-id',
	AUTH_SIGNUP_PASSWORD = 'auth-signup-password',
	AUTH_RESET_PASSWORD = 'auth-reset-password',
	AUTH_LOGIN = 'auth-login',
	AUTH_LOGIN_MFA = 'auth-login-mfa',
	AUTH_LOGIN_OTHER = 'auth-login-other',
	MY_INDEX = 'my',
	MY_CERTIFICATIN = 'my-certification',
	MY_OTHER_MFA = 'my-mfa',
	MY_MEMBER_INDEX = 'my-member',
	MY_MEMBER_EDIT = 'my-member-edit',
	MY_SUBSCRIPTION = 'my-subscription',
	MY_SUBSCRIPTION_DETAIL = 'my-subscription-detail',
	MY_SUBSCRIPTION_EDIT = 'my-subscription-edit',
	MY_SUBSCRIPTION_CANCEL = 'my-subscription-cancel',
	MY_SUBSCRIPTION_CANCEL_FORM = 'my-subscription-cancel-form',
	MY_SUBSCRIPTION_CANCEL_COMPLETED = 'my-subscription-cancel-completed',
	MY_BILL = 'my-bill',
	MY_INQUIRY = 'my-inquiry',

	MY_MEMBER_WITHDRAW_INDEX = 'my-member-withdraw',
	MY_MEMBER_WITHDRAW_REASON = 'my-member-withdraw-reason',
	WITHDRAW_DONE = 'withdraw-done',

	INQUIRY_WRITE = 'inquiry-write',
	INQUIRY_DONE = 'inquiry-done',

	FEATURE_INDEX = 'feature',
	SERVICE_INDEX = 'service',
	SERVICE_PLAN = 'service-plan',

	TRIAL_INDEX = 'trial',
	TRIAL_DONE = 'trial-done',
	SUBSCRIPTION_INDEX = 'subscription',
	SUBSCRIPTION_TERMS = 'subscription-terms',
	SUBSCRIPTION_REQUEST = 'subscription-request',
	SUBSCRIPTION_DONE = 'subscription-done',
	NOTICE_INDEX = 'notice'
}

export default RoutesConstants
