import RoutesConstants from '~~/home/types/route'

export default defineNuxtRouteMiddleware(async (to, from) => {
	console.log('🚀🚀🚀🚀🚀🚀🚀 라우트 미들웨어 실행 >>>>>>>>>>>')
	const authStore = useAuthStore()

	console.log('to.name :', to.name)

	const toName = String(to.name)

	let layout = 'user'

	if (toName.startsWith('errors')) {
		layout = 'error'
	} else {
		if (toName.startsWith('ui')) {
			layout = 'ui'
		} else if (toName.startsWith('test')) {
			layout = 'api'
		} else if (toName.startsWith('auth')) {
			layout = 'auth'
		} else if (toName.startsWith('inquiry')) {
			layout = 'main'
		} else if (toName.startsWith('trial')) {
			layout = 'main'
			console.log('trial - loggedIn:', authStore.loggedIn)
			if (authStore.loggedIn === false) {
				return navigateTo('/auth/login')
			}
		} else if (toName.startsWith('subscription')) {
			layout = 'main'
			if (authStore.loggedIn === false) {
				return navigateTo('/auth/login')
			}
		} else if (toName.startsWith('withdraw')) {
			layout = 'withdraw'
		} else if (toName.startsWith('my') && toName !== 'my') {
			layout = 'my'
			if (authStore.loggedIn === false) {
				console.log(2)
				return navigateTo('/auth/login')
			} else if (authStore.passwordEntered === false) {
				if (toName.startsWith('my-mfa')) {
					layout = 'auth'
				} else if (toName.startsWith('my-certification')) {
					layout = 'auth'
				} else {
					return navigateTo('/my')
				}
			}
		} else if (toName.startsWith('publish')) {
			layout = null
		} else {
			layout = 'main'
			if (authStore.loggedIn) {
				console.log('logged in')
			} else {
				console.log('middleware not logged in')
			}
		}
	}
	setPageLayout(layout)
})
