import BaseService, { type ResponseWrapperType } from '~~/home/services/BaseService'
import type {
	UserBillRequestType,
	UserBillResponseType,
	UserInfoResponseType,
	UserInfoUpdateRequestType,
	UserInfoUpdateResponseType,
	UserSessionResponseType,
	UserSubscriptionDetailRequestType,
	UserSubscriptionResponseType,
	UserSubscriptionStatusUpdateRequestType,
	UserSubscriptionStatusUpdateResponseType,
	UserWithdrawalPaymentRequestType,
	UserWithdrawalPaymentResponseType,
	UserWithdrawalReasonResponseType,
	UserWithdrawalRequestType,
	UserWithdrawalResponseType
} from '~~/home/types/user/userTypes'
import type {
	InquiryDetailRequestType,
	InquiryDetailResponseType,
	InquiryListRequestType,
	InquiryListResponseType
} from '~~/home/types/inquiry/inquiryTypes'
import type {
	SubscriptionActiveCountResponseType,
	SubscriptionCancelRequestType,
	SubscriptionCountResponseType,
	SubscriptionDetailType,
	SubscriptionJoinRequestType,
	SubscriptionJoinResponseType
} from '~~/home/types/subscription/subscriptionTypes'

class UserService extends BaseService {
	async getInfo(): Promise<ResponseWrapperType<UserInfoResponseType>> {
		const result = await this.post<null, UserInfoResponseType>('/user/info', null)
		if (result.code === 200 && result.data) {
			result.data.email = this.base64Decode(result.data.email)
			result.data.name = this.base64Decode(result.data.name)
			result.data.phoneNumber = this.base64Decode(result.data.phoneNumber)
			result.data.subEmail = this.base64Decode(result.data.subEmail)
			result.data.companyName = this.base64Decode(result.data.companyName)
		}
		return result
	}

	async updateInfo(body: UserInfoUpdateRequestType): Promise<ResponseWrapperType<UserInfoUpdateResponseType>> {
		const data = {
			email: this.base64Encode(body.email),
			name: this.base64Encode(body.name),
			phoneNumber: this.base64Encode(body.phoneNumber),
			// subEmail: this.base64Encode(body.subEmail),
			companyName: body.companyName
		}
		return await this.post('/api/service/home/v1.0/user/info/update', data)
	}

	async getCancelReasonList(): Promise<ResponseWrapperType<UserWithdrawalReasonResponseType>> {
		return await this.post('/api/service/home/v1.0/user/subscription/withdrawal/reason/list', {})
	}

	async cancelSubscription(body: UserWithdrawalRequestType): Promise<ResponseWrapperType<UserWithdrawalResponseType>> {
		return await this.post('/api/service/home/v1.0/user/subscription/withdrawal', body)
	}

	async editSubscription(body: SubscriptionJoinRequestType): Promise<ResponseWrapperType<SubscriptionJoinResponseType>> {
		return await this.post('/api/service/home/v1.0/user/subscription/update', body)
	}

	async updateSubscriptionStatus(
		body: UserSubscriptionStatusUpdateRequestType
	): Promise<ResponseWrapperType<UserSubscriptionStatusUpdateResponseType>> {
		return await this.post('/api/service/home/v1.0/user/subscription/status/update', body)
	}

	async getSubscriptionCancelPayment(
		body: UserWithdrawalPaymentRequestType
	): Promise<ResponseWrapperType<UserWithdrawalPaymentResponseType>> {
		return await this.post('/api/service/home/v1.0/user/subscription/withdrawal/estimated/payment/amount', body)
	}

	async getSubscriptions(value?: number): Promise<ResponseWrapperType<UserSubscriptionResponseType>> {
		const resp = await this.post('/api/service/home/v1.0/user/subscription/list', {
			subscriptionState: value
		})
		if (resp.code === 200 && resp.data && resp.data.subscriptions) {
			for (let i = 0; i < resp.data.subscriptions.length; i++) {
				resp.data.subscriptions[i].corporateName = this.base64Decode(resp.data.subscriptions[i].corporateName)
				resp.data.subscriptions[i].corporateEmail = this.base64Decode(resp.data.subscriptions[i].corporateEmail)
			}
		}
		return resp
	}

	async getSubscriptionDetail(body: UserSubscriptionDetailRequestType): Promise<ResponseWrapperType<SubscriptionDetailType>> {
		const resp = await this.post('/api/service/home/v1.0/user/subscription/detail', body)
		if (resp.code === 200 && resp.data) {
			resp.data = this.decodeBase64SubscriptionDetailType(resp.data)
		}
		return resp
	}

	async getUnauthSubscriptionDetail(body: UserSubscriptionDetailRequestType): Promise<ResponseWrapperType<SubscriptionDetailType>> {
		const resp = await this.post('/api/service/home/v1.0/subscription/detail', body)
		if (resp.code === 200 && resp.data) {
			resp.data = this.decodeBase64SubscriptionDetailType(resp.data)
		}
		return resp
	}

	// 로그인 했을 때 사용 가능
	async getSession(): Promise<ResponseWrapperType<UserSessionResponseType>> {
		const resp = await this.post('/api/service/home/v1.0/user/session/info', {})
		if (resp?.data) {
			if (resp.data.name) resp.data.name = this.base64Decode(resp.data.name)
			if (resp.data.email) resp.data.email = this.base64Decode(resp.data.email)
			if (resp.data.phoneNumber) resp.data.phoneNumber = this.base64Decode(resp.data.phoneNumber)
			if (resp.data.subEmail) resp.data.subEmail = this.base64Decode(resp.data.subEmail)
		}
		return resp
	}

	// 로그인 없이 세션 체크 가능
	async getUnauthSession(): Promise<ResponseWrapperType<UserSessionResponseType>> {
		const resp = await this.post('/api/service/home/v1.0/unauth/user/session/info', {})
		if (resp?.data) {
			if (resp.data.name && resp.data.name !== '') resp.data.name = this.base64Decode(resp.data.name)
			if (resp.data.email && resp.data.email !== '') resp.data.email = this.base64Decode(resp.data.email)
			if (resp.data.phoneNumber && resp.data.phoneNumber !== '') resp.data.phoneNumber = this.base64Decode(resp.data.phoneNumber)
			if (resp.data.subEmail && resp.data.subEmail !== '') resp.data.subEmail = this.base64Decode(resp.data.subEmail)
		}
		return resp
	}

	async getBill(body: UserBillRequestType): Promise<ResponseWrapperType<UserBillResponseType>> {
		return await this.post('/api/service/home/v1.0/user/bill', body)
	}

	async getInquiryList(body: InquiryListRequestType): Promise<ResponseWrapperType<InquiryListResponseType>> {
		return await this.sendWithContext('POST', '/api/community/v1.0/home/inquiry/list', body)
	}

	async getInquiryDetail(body: InquiryDetailRequestType): Promise<ResponseWrapperType<InquiryDetailResponseType>> {
		return await this.sendWithContext('POST', '/api/community/v1.0/home/inquiry/detail', body)
	}

	async getSubscriptionActiveCount(): Promise<ResponseWrapperType<SubscriptionActiveCountResponseType>> {
		return await this.post('/api/service/home/v1.0/user/subscription/active/count', {})
	}

	async getSubscriptionCount(): Promise<ResponseWrapperType<SubscriptionCountResponseType>> {
		return await this.post('/api/service/home/v1.0/user/subscription/count', {})
	}

	async getSubscriptionCancel(body: SubscriptionCancelRequestType): Promise<ResponseWrapperType<null>> {
		return await this.post('/api/service/home/v1.0/user/subscription/cancel', body)
	}

	base64Encode(str: string | undefined) {
		if (!str) {
			return str
		}
		const encoder = new TextEncoder()
		const encodedBytes = encoder.encode(str)
		return btoa(String.fromCharCode(...encodedBytes))
	}

	base64Decode(base64Str: string | undefined) {
		if (!base64Str) {
			return base64Str
		}
		const decodedBytes = Uint8Array.from(atob(base64Str), (c) => c.charCodeAt(0))
		return new TextDecoder().decode(decodedBytes)
	}
	decodeBase64SubscriptionDetailType(body: SubscriptionDetailType): SubscriptionDetailType {
		const data = {
			subscriptionInfo: body.subscriptionInfo,
			rnImgViewInfo: body.rnImgViewInfo,
			ddlImgViewInfo: body.ddlImgViewInfo,
			companyInfo: {
				rnno: this.base64Decode(body.companyInfo?.rnno),
				corporateNo: this.base64Decode(body.companyInfo?.corporateNo),
				ownerName: this.base64Decode(body.companyInfo?.ownerName),
				companyName: body.companyInfo?.companyName,
				businessType: body.companyInfo?.businessType,
				businessCategory: body.companyInfo?.businessCategory,
				zipCode: this.base64Decode(body.companyInfo?.zipCode),
				address: this.base64Decode(body.companyInfo?.address),
				addressDetail: this.base64Decode(body.companyInfo?.addressDetail),
				phoneNumber: this.base64Decode(body.companyInfo?.phoneNumber)
			},
			subscriberInfo: {
				accountType: body.subscriberInfo?.accountType,
				accountTypeDetail: body.subscriberInfo?.accountTypeDetail,
				accountEmail: this.base64Decode(body.subscriberInfo?.accountEmail),
				// nationality: body.subscriberInfo?.national,
				name: this.base64Decode(body.subscriberInfo?.name),
				zipCode: this.base64Decode(body.subscriberInfo?.zipCode),
				address: this.base64Decode(body.subscriberInfo?.address),
				addressDetail: this.base64Decode(body.subscriberInfo?.addressDetail),
				phoneNumber: this.base64Decode(body.subscriberInfo?.phoneNumber),
				companyName: body.subscriberInfo?.companyName,
				businessType: body.subscriberInfo?.businessType,
				industry: body.subscriberInfo?.industry,
				businessCategory: body.subscriberInfo?.businessCategory,
				// mno: this.base64Decode(body.subscriberInfo?.mno),
				rnno: this.base64Decode(body.subscriberInfo?.rnno),
				secNo: this.base64Decode(body.subscriberInfo?.secNo),
				companyOwner: this.base64Decode(body.subscriberInfo?.companyOwner)
			},
			paymentMethod: {
				annotation: body.paymentMethod?.annotation,
				paymentMethodCode: body.paymentMethod?.paymentMethodCode,
				paymentMethodName: body.paymentMethod?.paymentMethodName,
				paymentGiro: {
					deliveryMethod: '',
					companyName: '',
					billingRecipient: '',
					deptName: '',
					billingTelNumber: '',
					billingEmail: '',
					zipCode: '',
					address: '',
					addressDetail: ''
				},
				paymentCard: {
					cardHolderName: '',
					cardInfo: '',
					cardNumber: '',
					cardExpiration: '',
					withdrawAgreeYn: 'N',
					rnno: ''
				},
				paymentAccount: {
					accountHolderName: '',
					accountInfo: '',
					bankCode: '',
					rnno: ''
				}
			},
			adminInfo: {
				name: this.base64Decode(body.adminInfo?.name),
				deptName: body.adminInfo?.deptName,
				telNumber: this.base64Decode(body.adminInfo?.telNumber),
				phoneNumber: this.base64Decode(body.adminInfo?.phoneNumber),
				email: this.base64Decode(body.adminInfo?.email)
			},
			serviceInfo: body.serviceInfo,
			contractInfo: body.contractInfo
		}
		if (body.paymentMethod?.paymentMethodCode === 'G') {
			data.paymentMethod.paymentGiro = {
				deliveryMethod: body.paymentMethod?.paymentGiro?.deliveryMethod || '',
				companyName: body.paymentMethod?.paymentGiro?.companyName || '',
				billingRecipient: this.base64Decode(body.paymentMethod?.paymentGiro?.billingRecipient) || '',
				deptName: body.paymentMethod?.paymentGiro?.deptName || '',
				billingTelNumber: this.base64Decode(body.paymentMethod?.paymentGiro?.billingTelNumber) || '',
				billingEmail: this.base64Decode(body.paymentMethod?.paymentGiro?.billingEmail) || '',
				zipCode: this.base64Decode(body.paymentMethod?.paymentGiro?.zipCode) || '',
				address: this.base64Decode(body.paymentMethod?.paymentGiro?.address) || '',
				addressDetail: this.base64Decode(body.paymentMethod?.paymentGiro?.addressDetail) || ''
			}
		} else if (body.paymentMethod?.paymentMethodCode === 'C') {
			data.paymentMethod.paymentCard = {
				cardHolderName: this.base64Decode(body.paymentMethod?.paymentCard?.cardHolderName) || '',
				cardInfo: body.paymentMethod?.paymentCard?.cardInfo || '',
				cardNumber: this.base64Decode(body.paymentMethod?.paymentCard?.cardNumber) || '',
				cardExpiration: this.base64Decode(body.paymentMethod?.paymentCard?.cardExpiration) || '',
				withdrawAgreeYn: body.paymentMethod?.paymentCard?.withdrawAgreeYn || '',
				rnno: this.base64Decode(body.paymentMethod?.paymentCard?.rnno) || ''
			}
		} else if (body.paymentMethod?.paymentMethodCode === 'A') {
			data.paymentMethod.paymentAccount = {
				accountHolderName: this.base64Decode(body.paymentMethod?.paymentAccount?.accountHolderName) || '',
				accountInfo: this.base64Decode(body.paymentMethod?.paymentAccount?.accountInfo) || '',
				bankCode: this.base64Decode(body.paymentMethod?.paymentAccount?.bankCode) || '',
				rnno: this.base64Decode(body.paymentMethod?.paymentAccount?.rnno) || ''
			}
		}
		return data
	}
}

export default UserService
