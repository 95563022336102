import { useUIStore } from '@/stores/ui'

export const useLoader = () => {
	const uiStore = useUIStore()
	const isLoading = computed(() => uiStore.isLoading)
	// const storeKey = computed(() => uiStore.storeKey)

	const start = (key = 'page') => {
		uiStore.setLoading(true, key)
	}

	const finish = (key = 'page') => {
		uiStore.setLoading(false, key)
	}

	const clear = () => {
		uiStore.setLoadingForce(false)
	}

	return { isLoading, start, finish, clear }
}
