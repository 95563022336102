import * as enums from '~/utils/enums'
import * as codes from '~/utils/codes'

export default defineNuxtPlugin({
	name: 'init-plugin',
	async setup(nuxtApp) {
		// console.log('---api init:', process.server)
		// const authStore = await useAuthStore()
		return {
			provide: { enums, codes }
		}
	},
	env: {
		islands: true
	}
})
