import { useAuthStore } from '~/stores/auth'
// import service from '~~/admin/apis/admin/service'

export default defineNuxtPlugin({
	name: 'auth-plugin',
	async setup(nuxtApp) {
		const auth = useAuthStore()
		auth.restore()
		await auth.checkSession()

		return {
			provide: { auth }
		}
	},
	env: {
		islands: true
	}
})
