export type ResponseWrapperType<T> = {
	code: number
	msg: string
	data?: T
}

export type RequestMethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

class BaseService {
	http: any
	heauth: any
	idb: any
	auth: any
	secure: any
	host: string
	basePath: string
	frontHost: string
	frontPath: string
	proxyYn: boolean
	isDev: boolean

	constructor(basePath?: string) {
		const { $config, $http, $heauth, $idb, $secure, $auth } = useNuxtApp()
		this.http = $http
		this.heauth = $heauth
		this.secure = $secure
		this.auth = $auth
		this.idb = $idb
		this.host = $config.app.serverApi
		this.basePath = basePath ?? $config.app.serverPath
		this.isDev = $config.public.isDev
		this.proxyYn = $config.public.proxyYn === 'Y'
		if (!this.proxyYn) {
			this.basePath = $config.public.serverApi + this.basePath
		}
		this.frontHost = $config.app.frontHost
		this.frontPath = $config.app.baseURL
	}

	getServerPrefix = (url: string): string => {
		if (url.startsWith('/api/')) {
			// return this.host
			return ''
		} else {
			return this.basePath
		}
	}

	async send<T, R>(method: RequestMethodType, url: string, body: T) {
		// const basePath=  this.host + this.basePath
		const basePath = this.getServerPrefix(url)
		//this.http.post(url, data, {})

		console.log('send', method, basePath + url, body)

		if (method === 'POST') {
			const options = { body, initialCache: false, timeout: 20000 }
			return this.http.post(basePath + url, options)
		} else if (method === 'PUT') {
			const options = { body, initialCache: false }
			return this.http.put(basePath + url, options)
		} else if (method === 'GET') {
			const options = { query: body, initialCache: false }
			return this.http.get(basePath + url, options)
		} else {
			const options = { body, initialCache: false }
			return this.http.get(basePath + url, options)
		}
	}

	async sendWithContext<T, R>(method: RequestMethodType, url: string, body: T) {
		// const basePath=  this.host + this.basePath
		//this.http.post(url, data, {})
		/*
        let baseUrl = ''
        if (url.startsWith('/api/')) {
            baseUrl = this.host
        }
         */
		const baseUrl = ''

		if (method === 'POST') {
			const options = { body, initialCache: false }
			return this.http.post(baseUrl + url, options)
		} else if (method === 'PUT') {
			const options = { body, initialCache: false }
			return this.http.put(url, options)
		} else if (method === 'GET') {
			const options = { query: body, initialCache: false }
			return this.http.get(url, options)
		} else {
			const options = { body, initialCache: false }
			return this.http.get(url, options)
		}
	}

	async post<T, R>(url: string, body: T) {
		return await this.send('POST', url, body)
	}

	async get<T, R>(url: string, body: T) {
		return this.send('GET', url, body)
	}

	async put<T, R>(url: string, body: T) {
		return this.send('PUT', url, body)
	}

	async del<T, R>(url: string, body: T) {
		return this.send('DELETE', url, body)
	}

	async upload(url: string, form: FormData) {
		return this.http.upload(url, { body: form })
	}

	/**
	 * 인증 이외에 가능하면 사용하지 않음
	 * @param method
	 * @param url
	 * @param body
	 */
	async fetch<T, R>(method: RequestMethodType, url: string, body: T) {
		//this.http.post(url, data, {})
		const basePath = this.basePath
		const options = { method: method, retry: false, timeout: 20000, body }
		return useAsyncData('url', (): Promise<ResponseWrapperType<R>> => {
			return $fetch(basePath + url, options)
		})
	}

	async raw<T>(method: RequestMethodType, url: string, body: T | undefined | null): Promise<T> {
		const basePath = this.basePath
		return body ? await this.http.raw(basePath + url, { method, body }) : await this.http.raw(basePath + url, { method })
	}
}

export default BaseService
