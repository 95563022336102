import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_xns2MZGh6s from "/app/build/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_ghbUAjaD3n from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_Jozdw60ZsE from "/app/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import primevue_plugin_QtszrQiYxE from "/app/build/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/app/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import _00_init_GdlszUnu6d from "/app/src/plugins/00.init.ts";
import _10_http_l7gBhAumZ2 from "/app/src/plugins/10.http.ts";
import _20_auth_8EqUwR656x from "/app/src/plugins/20.auth.ts";
import _40_ui_1zSyTD6uKu from "/app/src/plugins/40.ui.ts";
import _50_init_client_QOMWVzRHAG from "/app/src/plugins/50.init.client.ts";
import _60_disable_log_nVi1cEIDj9 from "/app/src/plugins/60.disable.log.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_xns2MZGh6s,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_ghbUAjaD3n,
  plugin_Jozdw60ZsE,
  primevue_plugin_QtszrQiYxE,
  plugin_client_8fz26Kfk5j,
  _00_init_GdlszUnu6d,
  _10_http_l7gBhAumZ2,
  _20_auth_8EqUwR656x,
  _40_ui_1zSyTD6uKu,
  _50_init_client_QOMWVzRHAG,
  _60_disable_log_nVi1cEIDj9
]