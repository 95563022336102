<template>
	<NuxtLayout class="ak">
		<NuxtPage />
	</NuxtLayout>

	<Toast position="top-center" group="toast" :pt="{ buttonContainer: 'p-toast-message-close' }" class="pp-toast">
		<template #icon>
			<div class="p-toast-message-icon">
				<PIcon name="toast-check" color="white" />
			</div>
		</template>
		<template #closeicon>
			<PIcon name="toast-close" />
		</template>
	</Toast>
	<DynamicDialog />
	<UILoader />
	<ConfirmDialog>
		<template #container="{ message, acceptCallback, rejectCallback }">
			<div class="p-confirm-dialog-content">
				<span class="font-bold text-2xl block mb-2 mt-4" v-if="message.header">{{ message.header }}</span>
				<!-- <p class="mb-0">{{ message.message }}</p> -->
				<div class="text-area">
					<p v-for="m in message.message.split('<br />')" class="mb-0 message-alert">{{ m }}</p>
				</div>
				<div class="btn-area">
					<PButton
						:label="message.rejectLabel || '취소'"
						@click="rejectCallback"
						class="btn-confirm btn-confirm-cancel"
						secondary
					/>
					<PButton :label="message.acceptLabel || '확인'" @click="acceptCallback" class="btn-confirm btn-confirm-ok" primary />
				</div>
			</div>
		</template>
	</ConfirmDialog>
	<ConfirmDialog group="alert" class="p-alert">
		<template #container="{ message, acceptCallback }">
			<div class="p-confirm-dialog-content">
				<span class="font-bold text-2xl block mb-2 mt-4" v-if="message.header">{{ message.header }}</span>
				<div class="text-area">
					<p v-for="m in message.message.split('<br />')" class="mb-0 message-confirm">{{ m }}</p>
				</div>
				<div class="btn-area">
					<PButton label="확인" class="btn-alert btn-alert-ok" primary @click="acceptCallback" />
				</div>
			</div>
		</template>
	</ConfirmDialog>
</template>

<script setup lang="ts">
import DynamicDialog from 'primevue/dynamicdialog'
import ConfirmDialog from 'primevue/confirmdialog'
import UILoader from '~~/home/components/UILoader.vue'
import { onMounted } from 'vue'

const $route = useRoute()
const { $auth } = useNuxtApp()

onMounted(() => {
	setTimeout(() => {
		window.scrollTo(0, 0)
	}, 10)
})

watch(
	() => $route.name,
	() => {
		let layout = 'user'
		const toName = String($route.name)

		if (toName.startsWith('errors')) {
			layout = 'error'
		} else {
			if (toName.startsWith('ui')) {
				layout = 'ui'
			} else if (toName.startsWith('test')) {
				layout = 'api'
			} else if (toName.startsWith('auth')) {
				layout = 'auth'
			} else if (toName.startsWith('inquiry')) {
				layout = 'main'
			} else if (toName.startsWith('trial')) {
				layout = 'main'
				console.log('trial - loggedIn:', $auth.loggedIn)
				// if ($auth.loggedIn === false) {
				// 	return navigateTo('/auth/login')
				// }
			} else if (toName.startsWith('subscription')) {
				layout = 'main'
				// if ($auth.loggedIn === false) {
				// 	return navigateTo('/auth/login')
				// }
			} else if (toName.startsWith('withdraw')) {
				layout = 'withdraw'
			} else if (toName.startsWith('my') && toName !== 'my') {
				layout = 'my'
				// if ((authStore.loggedIn && authStore.passwordEntered) === false) {
				// 	console.log(1)
				// 	return navigateTo('/my')
				// } else if (authStore.loggedIn === false) {
				// 	console.log(2)
				// 	return navigateTo('/auth/login')
				// }
			} else {
				layout = 'main'
				if ($auth.loggedIn) {
					console.log('logged in')
				} else {
					console.log('middleware not logged in')
				}
			}
		}
	}
)
</script>
