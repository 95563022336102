import { useLoader } from '@/composables/useLoader'

export default defineNuxtPlugin({
	name: 'init-client-plugin',
	async setup(nuxtApp) {
		// console.log('---api init:', process.server)
		// useUIStore()
	},
	hooks: {
		'app:created'() {
			const nuxtApp = useNuxtApp()
		},
		'page:loading:start'() {
			// console.log('page:loading:start', process.client)
			if (!useLoader) return
			const loader = useLoader()
			if (import.meta.client && !loader.isLoading) loader.start('page')
		},
		'page:loading:end'() {
			// console.log('page:loading:end', process.client)
			if (!useLoader) return
			const loader = useLoader()
			if (import.meta.client && loader.isLoading) loader.finish('page')
		},
		'page:start'() {
			// console.log('page:start', process.client)
		},
		'page:finish'() {
			// if (process.client) {
			//   const wrapper = document.querySelector('.page-layout .page-wrap')
			//   if (wrapper) {
			//     wrapper.addEventListener('scroll', () => {
			//       // history.state.scroll
			//       console.log('scroll', history.state.scroll, wrapper.scrollTop)
			//     })
			//   }
			// }
			// console.log('page:finish', process.client)
		}
	},
	env: {
		islands: true
	}
})
