<template>
	<NuxtIcon v-bind="$attrs" :name="$props.name" filled class="pp-icon" :style="styles" />
</template>

<script setup lang="ts">
defineOptions({ inheritAttrs: true })

const props = defineProps({
	name: {
		type: String,
		required: true
	},
	color: {
		type: String,
		default: 'neutral'
	}
})

const styles = computed(() => {
	return {
		'--target-color': `var(--color-${props.color}-700, transparent)`
	}
})
</script>

<style lang="scss" scoped>
.pp-icon {
	--target-color: var(--color-error-700);
	:deep(svg) {
		.color-s {
			stroke: var(--target-color);
		}
		.color-f {
			fill: var(--target-color);
		}
	}
}
</style>
