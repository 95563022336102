import numberTransform from '~/directives/numberTransform'
import dateTransform from '~/directives/dateTransform'
import { usePrimeVue } from 'primevue/config'

export default defineNuxtPlugin({
	name: 'ui-plugin',
	async setup(nuxtApp) {
		const primevue = usePrimeVue()
		if (primevue.config.locale) {
			primevue.config.locale.monthNames = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']
			primevue.config.locale.dayNamesMin = ['일', '월', '화', '수', '목', '금', '토']
		}

		nuxtApp.vueApp.directive('num', numberTransform)
		nuxtApp.vueApp.directive('date', dateTransform)
	},
	env: {
		islands: true
	}
})
