export default {
  error: () => import("/app/src/layouts/error.vue").then(m => m.default || m),
  home: () => import("/app/src/layouts/home.vue").then(m => m.default || m),
  login: () => import("/app/src/layouts/login.vue").then(m => m.default || m),
  user: () => import("/app/src/layouts/user.vue").then(m => m.default || m),
  api: () => import("/app/home/layouts/api.vue").then(m => m.default || m),
  auth: () => import("/app/home/layouts/auth.vue").then(m => m.default || m),
  "auth-layout-auth-subtitle-component": () => import("/app/home/layouts/authLayout/AuthSubtitleComponent.vue").then(m => m.default || m),
  "common-banner": () => import("/app/home/layouts/common/Banner.vue").then(m => m.default || m),
  main: () => import("/app/home/layouts/main.vue").then(m => m.default || m),
  "main-layout-main-footer": () => import("/app/home/layouts/mainLayout/MainFooter.vue").then(m => m.default || m),
  "main-layout-main-header": () => import("/app/home/layouts/mainLayout/MainHeader.vue").then(m => m.default || m),
  "main-layout-main-header-temp": () => import("/app/home/layouts/mainLayout/MainHeaderTemp.vue").then(m => m.default || m),
  "main-layout-main-subtitle-component": () => import("/app/home/layouts/mainLayout/MainSubtitleComponent.vue").then(m => m.default || m),
  my: () => import("/app/home/layouts/my.vue").then(m => m.default || m),
  withdraw: () => import("/app/home/layouts/withdraw.vue").then(m => m.default || m)
}